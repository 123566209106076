.error-container {
  display: flex;
  width: 620px;
  margin: 0 auto;
  margin-top: 30vh;
}
.content {
  margin-left: 16px;
}
.tt {
  padding-top: 10px;
  font-size: 24px;
  font-weight: 600;
  color: #1f2129;
}
.recommend {
  margin-top: 28px;
  font-size: 14px;
  color: #1f2129;
}
.feedback {
  font-size: 14px;
  line-height: 1.71;
  color: #205be6;
}
.retry-wrap {
  margin-top: 6px;
  height: 36px;
}
.btn-retry {
  display: inline-block;
  width: 136px;
  height: 36px;
  border-radius: 2px;
  background: #ffcc32;
  font-size: 16px;
  line-height: 36px;
  color: #1f2129;
  text-align: center;
}
.btn-retry .DCD_Icon{
  display: inline-block;
  content: '';
  font-size: 20px;
  margin-right: 4px;
  vertical-align: middle;
}
.nav-wrap {
  margin-top: 6px;
  overflow: hidden;
}
.nav-wrap li {
  float: left;
  margin-right: 24px;
}
.nav-wrap li span {
  font-size: 14px;
  line-height: 1.43;
  font-size: 14px;
  color: #979aa8;
}
.nav-wrap li a:after {
  display: inline-block;
  content: '';
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #606370;
  margin-left: 5px;
}
.nav-wrap li:hover a:after {
  border-left: 6px solid #1f2129;
}
.nav-wrap li:hover span {
  color: #1f2129;
}